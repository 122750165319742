/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: #333;
    color: #fff;
    padding-top: 0;
}

.presentation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    text-align: center;
}

nav {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 60px;
    background: #ADD8E6;
    padding: 10px 0;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    margin-left: 20px;
    font-size: 24px;
    font-weight: 800;
    color: #333333;
}

.nav-links {
    display: flex;
    list-style: none;
    justify-content: center;
    gap: 20px;
    margin-left: auto;
    margin-right: 20px;
}

nav a {
    color: #333333;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 20px;
    transition: color 0.3s, background 0.3s;
}

nav a:hover {
    background: #387dc3;
    color: #ffffff;
    border-radius: 5px;
}

.menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 10px 20px;
}

.menu-icon .line {
    width: 25px;
    height: 3px;
    background: #fff;
    margin: 4px 0;
    transition: all 0.3s ease;
}

.menu-icon .line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon .line.open:nth-child(2) {
    opacity: 0;
}

.menu-icon .line.open:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
}

section {
    padding: 80px 100px;
}

section h1,
section h2 {
    text-align: center;
    margin-bottom: 40px;
}

section p {
    max-width: 800px;
    margin: 0 auto 20px;
    line-height: 1.8;
    font-size: 18px;
}

button {
    padding: 10px 20px;
    background: #ADD8E6;
    color: #333333;
    border: 2px solid #ADD8E6;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

button:hover {
    background: #387dc3;
    color: #ffffff;
}

.project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin: 0 auto;
}

.project-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f0f0f0;
    color: #333;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.project-card:hover {
    transform: translateY(-10px);
}

.project-card h3 {
    margin-bottom: 15px;
}

.project-card p {
    margin-bottom: 20px;
    line-height: 1.8;
}

.project-card a {
    color: #ADD8E6;
    text-decoration: none;
    font-weight: bold;
}

.project-card a:hover {
    color: #387dc3;
}

form {
    max-width: 600px;
    margin: 0 auto;
}

form label {
    display: block;
    margin-top: 20px;
    font-weight: bold;
}

form input,
form textarea {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

form textarea {
    resize: vertical;
    min-height: 150px;
}

form button {
    margin-top: 30px;
    width: 100%;
}

footer {
    background: #f0f0f0;
    color: #333;
    text-align: center;
    padding: 20px 0;
}

footer p {
    margin: 0;
}

@media (max-width: 768px) {
    .menu-icon {
        display: flex;
    }

    .nav-links {
        position: fixed;
        top: 60px;
        right: 0;
        width: 100%;
        height: calc(100vh - 60px);
        background: #007BFF;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .nav-links.open {
        transform: translateX(0);
    }

    .nav-links li {
        margin: 20px 0;
    }

    .nav-links a {
        font-size: 24px;
    }

    section {
        padding: 60px 20px;
    }

    .project-list {
        flex-direction: column;
        align-items: center;
    }

    .about-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .home-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-weight: normal;
    }

    .skills,
    .interests {
        width: 100%;
    }

    .about-content .skills,
    .about-content .interests {
        margin-top: 20px;
    }
}

nav::after {
    content: "";
    display: table;
    clear: both;
}

#home {
    margin-top: 60px;
}

#home button {
    margin-top: 30px;
}

.home-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-weight: normal;
}

.home-content h1 {
    display: block;
    margin: 0;
}

.home-content .home-content-name {
    font-size: 40px;
    font-weight: bold;
}

.about-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    text-align: left;
}

.skills {
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
}

.skills h2 {
    margin-bottom: 20px;
}

.skills-list {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.skills-list li {
    list-style: none;
    background: #6c757d;
    color: #fff;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 15px;
}

.skills-list li:hover {
    background: #387dc3;
}

.interests {
    margin-top: 40px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.interests h2 {
    margin-bottom: 20px;
}

.interests p {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
    font-size: 18px;
}

.skills {
    flex: 1;
}

.interests {
    flex: 2;
}

.about-content img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.about-image {
    width: 300px;
    height: auto;
    display: block;
    margin: 0 auto 20px;
}


.hi-there {
    font-size: 48px;
    color: #ff5733;
    text-align: center;
    margin-bottom: 20px;
}

.projectLink {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.resume-section {
    text-align: center;
}

.resume-preview {
    display: inline-block;
    overflow: auto;
    width: 60%;
    height: 50%;
}

.resume-preview-iframe {
    width: 800px;
    height: 400px;
    transform: scale(0.8);
    transform-origin: 0 0;
    border: none;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.popup-content {
    position: relative;
    margin: 5% auto;
    width: 80%;
    background: #fff;
    color: #000;
    padding: 20px;
}

.resume-full-iframe {
    width: 100%;
    height: 600px;
}

body.popup-open {
    overflow: hidden;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    width: 90%;
    max-width: 1200px;
    max-height: 90%;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}

.iframe-container {
    flex: 1;
    overflow: auto;
}

.presentation-iframe {
    width: 100%;
    height: 500px;
    border: none;
}

.paper-iframe {
    width: 100%;
    height: 800px;
    border: none;
}

.close-button {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-button:hover {
    background-color: #555;
}

@media (max-width: 768px) {
    .presentation-iframe, .paper-iframe {
        height: 300px;
    }
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.button-group button {
    padding: 10px 15px;
    background-color: #ADD8E6;
    color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-group button:hover {
    background-color: #87CEFA;
}